@use "../../styles/variables.scss";

.programmes-by-career-stage,
.programmes-by-career-stage-comparison {
  .top-heading {
    .section-title {
      font-size: 1.4rem;
      text-transform: uppercase;
      font-family: var(--lbs-base-font-family), sans-serif;
      display: block;
      margin-bottom: 2.8rem;
    }
  }

  .title-with-intro {
    display: flex;

    .left-section {
      width: 50%;
      padding-right: 4.5rem;

      h2 {
        font-size: 4.8rem;
      }
    }

    .right-section {
      width: 50%;
      padding-left: 4.5rem;

      p {
        font-size: 2rem;
        margin-bottom: 3.5rem;
      }

      .error {
        margin-top: 2rem;
        margin-bottom: 3rem;
        font-size: 1.6rem;
        display: none;
      }

      .dropdown {
        max-width: 25.7rem;
        max-height: 6rem;
        position: relative;
        background: variables.$projectBone;
        z-index: 1;

        // Moved to Vertical Accordion
        // .toggle-link {
        //   &::after,
        //   &::before {
        //     display: none;
        //   }

        //   .chevron {
        //     width: 1rem;
        //     height: 1rem;
        //     position: absolute;
        //     right: 1.5rem;
        //     top: 50%;
        //     transform: translate(0, -50%);

        //     &::after {
        //       width: 100%;
        //       height: 100%;
        //       transform: translate(0, -50%) rotate(135deg);
        //     }
        //   }

        //   &[aria-expanded="true"] {
        //     .chevron {
        //       &::after {
        //         transform: translate(0) rotate(315deg);
        //       }
        //     }
        //   }
        // }

        ul {
          // Moved to vertical accordion
          // li {
          //   margin: 0;
          //   border: 1px solid rgba(0, 30, 98, 0.5);
          //   background: variables.$projectBone;

          //   .toggle-link {
          //     padding: 1.5rem 3rem 1.5rem 1rem;
          //     font-weight: 400;
          //   }

          //   .accordion-drop {
          //     padding: 0;

          //     .content {
          //       padding-left: 0;

          //       .form-row {
          //         margin: 1rem 0 0;
          //         padding: 0.75rem 1rem;
          //         display: flex;

          //         &:hover {
          //           background: variables.$projectDarkBone;

          //           label {
          //             font-weight: 400;
          //           }
          //         }
          //       }
          //     }
          //   }
          // }
        }

        // Moved to vertical accordion
        // .checkbox {
        //   width: 100%;

        //   .tick {
        //     top: 0.3rem;
        //   }
        // }
      }
    }
  }

  .filtered-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > .group-col {
      max-width: calc(33% - 2rem);
      width: 33.33%;
      flex-basis: 33.33%;
      margin-right: 2rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .vertical-accordion {
      // Copied to vertical accordion
      // @include variables.vertical-accordion;
      display: block;
      margin: 2rem 0;
      max-width: 100%;
      width: 100%;

      &.hide {
        display: none;
      }

      // Copied to vertical accordion
      // li {
      //   min-height: 9.2rem;

      //   &:last-child {
      //     display: flex;
      //     flex-direction: column;
      //     justify-content: space-evenly;

      //     a {
      //       &.toggle-link {
      //         padding: 0;
      //         min-height: 9.2rem;
      //         display: flex;

      //         .tick {
      //           background-image: none;
      //           bottom: 50%;
      //           transform: translate(0, 50%) rotate(90deg);
      //           right: 3rem;
      //         }

      //         &[aria-expanded="true"] {
      //           .tick {
      //             transform: translate(0, 50%) rotate(270deg);
      //           }
      //         }

      //         .top-section {
      //           width: 100%;
      //           display: flex;
      //           align-items: self-start;
      //         }
      //       }

      //       h4 {
      //         font-size: 2rem;
      //         line-height: 1.3;
      //         padding-right: 4rem;
      //         color: variables.$projectBlue;
      //       }
      //     }
      //   }

      //   .accordion-drop {
      //     .section-row-wrapper {
      //       .section-row {
      //         .message-wrapper {
      //           &.light {
      //             &:focus,
      //             &:hover {
      //               &::before {
      //                 background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
      //                 transform: none;
      //               }
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }

  &.blue {
    color: white;

    .title-with-intro {
      .right-section {
        // Moved to vertical accordion
        //   ul {
        //     li {
        //       background: variables.$projectBlue;
        //       border-color: rgba(250, 250, 250, 0.5);

        //       .toggle-link {
        //         background: variables.$projectBlue;
        //         color: white;

        //         &:hover {
        //           background: variables.$projectBlue;
        //         }

        //         .chevron {
        //           &::after {
        //             border-color: white;
        //           }
        //         }
        //       }

        //       .accordion-drop {
        //         .form-row {
        //           &:hover {
        //             background: variables.$projectDarkBlue;
        //           }
        //         }
        //       }
        //     }
        //   }

        //   .checkbox {
        //     .tick {
        //       border-color: white;
        //     }
        //   }
      }
    }

    // Copied to vertical accordion
    // .filtered-content {
    //   .vertical-accordion {
    //     li {
    //       .accordion-drop {
    //         height: 0;
    //         transition: height 0.3s;

    //         .description {
    //           p {
    //             color: variables.$projectBlue;
    //           }
    //         }

    //         .section-row-wrapper {
    //           background: variables.$projectBone;
    //           color: variables.$projectBlue;

    //           .section-row {
    //             .info {
    //               .sub-text {
    //                 color: variables.$projectBlue;
    //                 font-weight: 700;
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }

  &.error {
    .title-with-intro {
      .right-section {
        .error {
          display: block;
        }
      }
    }
  }
}
// COMPACT WEB
@media #{variables.$compact-web} {
  .programmes-by-career-stage,
  .programmes-by-career-stage-comparison {
  }
}
// TABLET PORTRAIT
@media #{variables.$tablet} {
  .programmes-by-career-stage,
  .programmes-by-career-stage-comparison {
    .filtered-content {
      > .group-col {
        max-width: 100%;
        width: calc(50% - 2rem);
        flex-basis: calc(50% - 2rem);

        &:nth-child(2) {
          margin-right: 0;
        }
      }
    }
  }
}
// MOBILE
@media #{variables.$mobile} {
  .programmes-by-career-stage,
  .programmes-by-career-stage-comparison {
    .top-heading {
      h1 {
        margin-bottom: 1.8rem;
      }
    }

    .title-with-intro {
      flex-direction: column;

      .left-section {
        padding-right: 0;
        width: 100%;

        h2 {
          font-size: 4rem;
          margin-bottom: 2.3rem;
        }
      }

      .right-section {
        width: 100%;
        padding-left: 0;

        .dropdown {
          max-width: none;
          margin-left: -1rem;
          margin-right: -1rem;

          > ul {
            margin-left: 0;
            margin-right: 0;
          }
        }

        // copied to vertical accordion

        //
        //   margin-bottom: 4rem;
        //   max-height: none;

        //   ul {
        //     li {
        //       .accordion-drop {
        //         .content {
        //           .form-row {
        //             max-width: none;
        //           }
        //         }
        //       }
        //     }
        //   }
        // }
      }
    }

    .filtered-content {
      flex-direction: column;

      > .group-col {
        max-width: none;
        width: 100%;
        flex-basis: initial;
      }

      // copied to vertical accordion
      .vertical-accordion {
        margin: 0 0 1.4rem;
        max-width: none;
      }
    }
  }
}
// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .programmes-by-career-stage,
  .programmes-by-career-stage-comparison {
  }
}
// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .programmes-by-career-stage,
  .programmes-by-career-stage-comparison {
  }
}

.programmes-by-career-stage-comparison {
  > .row {
    margin: 0;
  }

  .filters {
    &:global(.form) {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 1.3rem 0 3rem;

      .options-label {
        color: variables.$projectBlue;
        font-family: var(--lbs-base-font-family), sans-serif;
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin-right: 4.5rem;
      }

      :global(.form-row) {
        margin: 0;

        :global(.checkbox) {
          label {
            margin-top: 0.2rem;
          }

          &:hover {
            label {
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .error-message {
    margin-top: 2rem;
    margin-bottom: 3rem;
    font-size: 1.6rem;
    display: none;
  }

  // copied to vertical accordion
  // .compare-option {
  //   padding: 1.5rem 1.9rem 0.6rem;
  //   margin-top: -0.2rem;
  //   border-top: 0.1rem solid variables.$projectLightGrey;
  //   background-color: variables.$projectWhite;

  //   :global(.checkbox) {
  //     .label {
  //       padding-left: 3.5rem;
  //       font-size: 1.6rem;
  //     }
  //   }
  // }

  .filtered-content {
    .vertical-accordion {
      li {
        min-height: 11.4rem;

        &:last-child {
          // .external-link-container {

          //   .toggle-link {
          //     .top-section {
          //       padding: 2.1rem 2rem 2.1rem 0.5rem;
          //     }
          //   }
          //  }

          .toggle-link {
            .top-section {
              padding: 2.1rem 2rem;
            }

            :global(.tick) {
              bottom: auto;
              top: 1.7rem;
            }

            &[aria-expanded="true"] {
              :global(.tick) {
                right: 3.3rem;
              }
            }

            &.is-external {
              h4 {
                position: relative;
                padding-left: 3.4rem;

                &::before {
                  position: absolute;
                  content: "";
                  left: -0.2rem;
                  top: 0.1rem;
                  display: block;
                  width: 2.4rem;
                  height: 2.4rem;
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-image: url("../../public/assets2019/img/svg/external.svg");
                }
              }
            }
          }
        }

        // .external-link-container {
        //   display: flex;

        //   .icon-external {
        //     padding: 2.2rem 1rem 2rem 2rem;
        //     height: 100%;
        //     display: flex;
        //     align-items: flex-start;

        //     svg {
        //       width: 2.4rem;
        //       height: 2.4rem;
        //       fill: variables.$projectBlue;
        //     }
        //   }

        //   .top-section {
        //     padding-left: 1rem;
        //   }
        // }
      }
    }
  }

  &.error {
    .error-message {
      display: block;
    }
  }

  &:global(.blue) {
    .filters {
      &:global(.form) {
        .options-label {
          color: variables.$projectWhite;
        }

        :global(.tick) {
          border-color: variables.$projectWhite;
        }

        :global(.checkbox) {
          [type="checkbox"] {
            &:checked,
            &:hover,
            &:focus {
              + :global(.tick) {
                border-color: variables.$projectWhite;
              }
            }
          }
        }
      }
    }

    //copied to vertical accordion
    .compare-option {
      :global(.checkbox) {
        label {
          color: variables.$projectBlue;
        }
      }
    }
  }
}
// COMPACT WEB
@media #{variables.$compact-web} {
  .programmes-by-career-stage-comparison {
  }
}
// TABLET PORTRAIT
@media #{variables.$tablet} {
  .programmes-by-career-stage-comparison {
    .filters {
      &.form {
        flex-direction: column;
        align-items: flex-start;

        .form-row {
          margin: 1.3rem 0 0;
        }
      }
    }

    // copied to vertical accordion
    // .compare-option {
    //   padding-bottom: 1.5rem;

    //   :global(.checkbox) {
    //     margin-right: 0;
    //   }
    // }
    .filters {
      :global(.form-row.full) {
        margin: 1.3rem 0 0 0;
      }
    }
  }
}
// MOBILE
@media #{variables.$mobile} {
  .programmes-by-career-stage-comparison {
    .filters {
      &.form {
        flex-direction: column;
        align-items: flex-start;

        .form-row {
          margin: 1.3rem 0 3rem;
        }
      }
    }

    // copied to vertical accordion
    // .compare-option {
    //   padding-bottom: 1.5rem;

    //   :global(.checkbox) {
    //     margin-right: 0;
    //   }
    // }

    // .filtered-content {
    //   // .vertical-accordion {
    //   //   // li {
    //   //   //   &:last-child {
    //   //   //     .external-link-container {
    //   //   //       .toggle-link {
    //   //   //         .top-section {
    //   //   //           padding: 2.1rem 2rem 2.1rem 0;
    //   //   //         }
    //   //   //       }
    //   //   //     }
    //   //   //   }
    //   //   // }
    //   // }
    // }
  }
}
// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .programmes-by-career-stage-comparison {
  }
}
// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .programmes-by-career-stage-comparison {
  }
}
